<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">查找</div>
      <div class="topRight"></div>
    </div>
    <div class="tjhy">按条件搜索</div>
    <div class="province">
      <div>省份</div>
      <div @click="economize = true">
        <input type="text" v-model="province" placeholder="请选择省份" />
        <img src="../../image/quanz/colorxl.png" alt="" />
      </div>
    </div>
    <div class="province">
      <div>城市</div>
      <div @click="market = true">
        <input type="text" v-model="city" placeholder="请选择城市" />
        <img src="../../image/quanz/colorxl.png" alt="" />
      </div>
    </div>
    <div class="province">
      <div>县/区</div>
      <div @click="county = true">
        <input type="text" v-model="countyValue" placeholder="请选择县/区" />
        <img src="../../image/quanz/colorxl.png" alt="" />
      </div>
    </div>
    <div class="bntImg" @click="goSeekResult()"><img src="../../image/quanz/cz1.png" alt="" /></div>
    <!-- 省 -->
    <van-popup v-model="economize" round  position="bottom">
      <van-picker
        show-toolbar
        :columns="provinces"
        @confirm="onConfirm"
        @cancel="economize = false"
      />
    </van-popup>
    <!-- 市 -->
    <van-popup v-model="market" round  position="bottom">
      <van-picker
        show-toolbar
        :columns="cities"
        @confirm="onConfirm1"
        @cancel="market = false"
      />
    </van-popup>
    <!-- 县级 -->
    <van-popup v-model="county" round  position="bottom">
      <van-picker
        show-toolbar
        :columns="counties"
        @confirm="onConfirm2"
        @cancel="county = false"
      />
    </van-popup>
  </div>
</template>

<script>
import {getRegionTree} from "@/api/lookAt";

export default {
  data() {
    return {
      economize: false, //省份
      provinceId: "",
      province: "",
      market: false, //市级
      cityId: "",
      city: '',
      county: false, //县级
      countyId: "",
      countyValue: '',
      regionTree: '',
      provinces: [],
      cities: [],
      counties: [],
    };
  },
  mounted() {
    getRegionTree().then(res => {
      this.regionTree = res.data.data;
      for (let index in this.regionTree) {
        let value = {"text": this.regionTree[index].name, "data": this.regionTree[index]}
        this.provinces.push(value);
      }
    })
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 省级
    onConfirm(value) {
      this.province = value.text;
      let data = value.data;
      this.provinceId = data.id;
      for (let index in data.children) {
        let value = {"text": data.children[index].name, "data": data.children[index]}
        this.cities.push(value);
      }
      this.economize = false;
    },
    // 市级
    onConfirm1(value) {
      this.city = value.text;
      let data = value.data;
      this.cityId = data.id;
      for (let index in data.children) {
        let value = {"text": data.children[index].name, "data": data.children[index]}
        this.counties.push(value);
      }
      this.market = false;
    },
    // 县级
    onConfirm2(value) {
      this.countyValue = value.text;
      this.countyId = value.data.id;
      this.county = false;
    },
    // 查找结果
    goSeekResult() {
      if (this.provinceId == '' && this.cityId == '' && this.countyId == '') {
        alert("请选择区域！")
        return;
      }
      this.$router.push({ path: "/seekResult", query: {
        "provinceId": this.provinceId, "cityId": this.cityId, "countyId": this.countyId
        } });
    },
  },
};
</script>

<style scoped>
.setBox {
  height: 100vh;
}
.boxTop {
  width: 750px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 18px;
  background-color: #fff;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.topRight {
  width: 48px;
  height: 48px;
  margin-right: 32px;
}
.tjhy {
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  background: #fff;
  padding: 32px;
}
.province {
  margin-left: 32px;
  margin-bottom: 60px;
}
.province div:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
}
.province div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 686px;
  height: 92px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-top: 20px;
}
.province div:nth-child(2) input {
  width: 400px;
  border: none;
  font-size: 32px;
  background: #f6f8fa;
  margin-left: 24px;
}
.province div:nth-child(2) img {
  width: 32px;
  height: 32px;
  margin-right: 24px;
}
.bntImg img {
  width: 684px;
  height: 84px;
  margin-left: 32px;
  margin-top: 20px;
}
</style>
